import React, { useState } from 'react';
import axios from 'axios';
import './FileUpload.css';

const FileUpload = () => {
    const [templateFile, setTemplateFile] = useState(null);
    const [sourceFiles, setSourceFiles] = useState([]);
    const [resultFile, setResultFile] = useState(null);

    const handleTemplateChange = (e) => {
        setTemplateFile(e.target.files[0]);
    };

    const handleSourceFilesChange = (e) => {
        setSourceFiles(Array.from(e.target.files));
    };

    const handleUpload = async () => {
        const formData = new FormData();
        formData.append('TemplateFile', templateFile);
        sourceFiles.forEach(file => formData.append('SourceFiles', file));

        try {
            const response = await axios.post('./api/fileupload/upload', formData, {
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            setResultFile(url);
        } catch (error) {
            console.error('Error uploading files:', error);
        }
    };

    return (
        <div className="file-upload-container">
            <h1 className="file-upload-title">File Upload</h1>
            <div className="file-upload-input-group">
                <label className="file-upload-label">
                    Šablona (Template File):
                    <input type="file" onChange={handleTemplateChange} className="file-upload-input" />
                </label>
            </div>
            <div className="file-upload-input-group">
                <label className="file-upload-label">
                    Zdrojová data (Source Files):
                    <input type="file" multiple onChange={handleSourceFilesChange} className="file-upload-input" />
                </label>
            </div>
            <button onClick={handleUpload} className="file-upload-button">Export</button>
            {resultFile && (
                <div className="file-upload-result">
                    <h2 className="file-upload-result-title">Result File:</h2>
                    <a href={resultFile} download="vysledek.xls" className="file-upload-result-link">Download vysledek.xls</a>
                </div>
            )}
        </div>
    );
};

export default FileUpload;
